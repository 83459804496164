import React from "react";

import Link from "../components/link";
import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div>
      <h2>404 page not found</h2>

      <div className="p-4 pt-10 max-w-sm m-auto text-center">
        <p>
          No worries, it happens to the best of us.
          <br />
          Please go back to{" "}
          <Link to="/" className="text-electron hover:text-teal-darker">
            Home
          </Link>
          .
        </p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
